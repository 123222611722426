<template>
  <div v-if="ticketDetails.ticketid" class="add-container">
    <p class="section-header">Add Time</p>
    <div>
      <button v-if="!timers[timerIndex]" @click="createTimer(ticketid)" class="positive">
        START
      </button>
      <button v-else @click="stopTimer(timerIndex)" class="danger">
        STOP
      </button>
      <p class="clock" v-if="timers[timerIndex]">
        <span @click="timers[timerIndex].setAdjustTime(-60000)">-</span> {{ timers[timerIndex].formatedTime }}
        <span @click="timers[timerIndex].setAdjustTime(60000)">+</span>
      </p>
      <textarea
        v-if="timers[timerIndex]"
        v-model="timers[timerIndex].description"
        placeholder="notes..."
        name=""
        id=""
      ></textarea>
      <div v-if="timers[timerIndex]" class="controls">
        <img
          v-if="!timers[timerIndex].isPaused"
          @click="timers[timerIndex].togglePause"
          class="action-btn"
          src="../assets/pause.svg"
          alt=""
        />
        <img v-else @click="timers[timerIndex].togglePause" class="action-btn" src="../assets/play.svg" alt="" />
        <img @click="deleteTimeEntry(timerIndex)" class="action-btn" src="../assets/discard.svg" alt="" />
      </div>
    </div>
  </div>
  <div class="space-filler"></div>
</template>

<script>
import timeEntries from '../composables/timeEntries';
import ticket from '../composables/ticket';

export default {
  props: ['ticketid'],
  setup(props) {
    timeEntries.getOpenTimeEntries();

    return {
      timers: timeEntries.timers,
      createTimer: timeEntries.createTimer,
      stopTimer: timeEntries.stopTimer,
      timerIndex: timeEntries.timerIndex,
      deleteTimeEntry: timeEntries.deleteTimeEntry,
      ticketDetails: ticket.ticketDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-container {
  margin: 3px;
  margin-top: 25px;
  max-width: 800px;

  .section-header {
    width: 100%;
    font-size: 0.6em;
    border-bottom: 1px solid #dfdfdf;
  }
  & > div {
    display: grid;
    width: 100%;
    grid-template-columns: 150px 1fr 75px;
    grid-template-rows: 40px 40px;

    button {
      grid-area: 1 / 1 / 2 / 2;
      width: 120px;
      margin: 2px auto;
      padding: 0px;
    }
    .clock {
      grid-area: 2 / 1 / 3 / 2;
      width: 100%;
      text-align: center;
      font-size: 1.4em;
      margin: auto;
      span {
        padding: 0px 8px;
        cursor: pointer;
      }
    }
    textarea {
      grid-area: 1 / 2 / 3 / 3;
      width: 100%;
      margin: 2px;
      background: #dfdfdf;
      color: #3a3a3a;
      padding: 8px;
      resize: vertical;
      outline: none;
    }
    .controls {
      grid-area: 1 / 3 / 2 / 4;
      width: 100%;
      text-align: right;
      padding: 5px;
      img {
        width: 20px;
        height: 20px;
        &:last-child {
          margin-left: 15px;
        }
      }
    }
  }
}
.space-filler {
  height: 50px;
}
</style>

import axios from 'axios';
import { computed, nextTick, ref } from 'vue';
import global from './global';
import ticket from './ticket';
import ticketNotes from './ticketNotes';

class CustomTimer {
  accumulatedTime: number;
  startTime: number;
  ticketid: string;
  formatedTime: string;
  noteid: string;
  description: string;
  isPaused: boolean;
  accumulatedPausedTime: number;
  startPausedTime: number;
  adjustedTime: number;
  constructor(ticketid: string, noteid: string) {
    this.accumulatedTime = 0;
    this.startTime = 0;
    this.formatedTime = '0:00';
    this.ticketid = ticketid;
    this.noteid = noteid;
    this.description = '';
    this.isPaused = false;
    this.startPausedTime = 0;
    this.accumulatedPausedTime = 0;
    this.adjustedTime = 0;
  }
  startTimer(customStartTime?: number) {
    this.startTime = customStartTime || Date.now();
    setInterval(() => {
      this.updateTime();
    }, 1000);
  }
  updateTime() {
    if (!this.isPaused) {
      this.accumulatedTime = Date.now() - this.startTime - this.accumulatedPausedTime;
      this.formatedTime = this.millisToMinutesAndSeconds(this.accumulatedTime + this.adjustedTime);
    }
  }
  togglePause() {
    if (this.isPaused) {
      this.isPaused = false;
      this.accumulatedPausedTime += Date.now() - this.startPausedTime;
      this.startPausedTime = 0;
    } else {
      this.isPaused = true;
      this.startPausedTime = Date.now();
    }
  }
  setAdjustTime(amount: number) {
    this.adjustedTime += amount;
    this.updateTime();
  }
  millisToMinutesAndSeconds(millis: number) {
    const minutes = Math.floor(millis / 60000);
    const seconds = parseInt(((millis % 60000) / 1000).toFixed(0));
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }
}

const initTimers: CustomTimer[] = [];
const timers = ref(initTimers);

const createTimer = (ticketid: string) => {
  if (timers.value.findIndex((el) => el.ticketid == ticketid) == -1) {
    let noteid = '';
    const startTime = Date.now();
    axios
      .post(global.serverString + 'addtime', {
        userid: global.state.value.currentUserId,
        startTime: startTime,
        ticketid: ticketid,
      })
      .then((res) => {
        if (res.statusText != 'OK') throw 'status not OK';
        if (res.data.status != 'succeded') throw 'status not OK';
        noteid = res.data.noteid;
        timers.value.push(new CustomTimer(ticketid, noteid));
        nextTick(() => {
          timers.value[timers.value.length - 1].startTimer(startTime);
        });
      })
      .catch((err) => {
        if (err.response.data.status == 'Auth Failed') global.logoff();
        console.log(err);
      });
  }
};

const stopTimer = (index: number) => {
  const endtime = Date.now();
  if (timers.value[index].isPaused) {
    timers.value[index].togglePause();
  }
  axios
    .post(global.serverString + 'stoptime', {
      noteid: timers.value[index].noteid,
      endtime: endtime + timers.value[index].adjustedTime,
      description: timers.value[index].description,
      pausedTime: timers.value[index].accumulatedPausedTime,
    })
    .then((res) => {
      if (res.statusText != 'OK') throw 'status not OK';
      if (res.data.status != 'succeded') throw 'status not OK';
      timers.value.splice(index, 1);
      ticketNotes.getNotes(ticket.ticketDetails.value.ticketid!, true);
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    });
};

const deleteTimeEntry = (index: number) => {
  axios
    .delete(global.serverString + 'deletetime', {
      data: {
        noteid: timers.value[index].noteid,
      },
    })
    .then((res) => {
      if (res.statusText != 'OK') throw 'status not OK';
      if (res.data.status != 'succeded') throw 'status not OK';
      timers.value.splice(index, 1);
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    });
};

const getOpenTimeEntries = () => {
  axios
    .get(global.serverString + 'getopentimeentries', {
      params: {
        userid: global.state.value.currentUserId,
      },
    })
    .then((response) => {
      if (response.statusText != 'OK') throw 'status not OK';
      response.data.forEach((obj: any) => {
        if (timers.value.findIndex((v) => v.noteid.toUpperCase() == obj.noteid.toUpperCase()) == -1) {
          timers.value.push(new CustomTimer(obj.ticketid, obj.noteid));
          timers.value[0].startTimer(obj.starttime);
        }
      });
    })
    .catch((err) => {
      if (err.response.data.status == 'Auth Failed') global.logoff();
      console.log(err);
    });
};

const timerIndex = computed(() => {
  const index = timers.value.findIndex((el) => el.ticketid == ticket.ticketDetails.value.ticketid);
  if (index >= 0) {
    return index;
  } else {
    return null;
  }
});

const getTimerIndex = (ticketId: string) => {
  const index = timers.value.findIndex((el) => el.ticketid == ticketId);
  if (index >= 0) {
    return index;
  } else {
    return null;
  }
};

const externalPause = (ticketid: string) => {
  const index = getTimerIndex(ticketid);
  timers.value[index || 0].togglePause();
};

export default {
  timers,
  timerIndex,
  createTimer,
  stopTimer,
  deleteTimeEntry,
  getOpenTimeEntries,
  getTimerIndex,
  externalPause,
};

<template>
  <div v-if="(id && ticketDetails.description) || !id" class="main-panel">
    <div class="sub-panel">
      <div class="inputs">
        <input
          type="text"
          class="header-text"
          v-model="ticketDetails.description"
          spellcheck="false"
          placeholder="description"
        />
        <ClientDetails />
        <select :class="{ 'not-placeholder': ticketDetails.assignedid != '' }" v-model="ticketDetails.assignedid">
          <option :value="''" disabled selected>assigned to</option>
          <option v-for="user in globalState.users" :key="user.userid" :value="user.userid">{{ user.username }}</option>
        </select>
        <select :class="{ 'not-placeholder': ticketDetails.priority != 0 }" v-model="ticketDetails.priority">
          <option :value="0" disabled selected>priority</option>
          <option :value="1">Low</option>
          <option :value="2">Medium</option>
          <option :value="3">High</option>
        </select>
        <input
          type="text"
          autocomplete="chrome-off"
          placeholder="due date"
          onfocus="(this.type = 'date')"
          onblur="(this.type = 'text')"
          v-model="ticketDetails.duedate"
        />
        <input
          v-if="renderedClosedDate"
          type="text"
          autocomplete="chrome-off"
          placeholder="dateclosed"
          onfocus="(this.type = 'date')"
          onblur="(this.type = 'text')"
          :value="renderedClosedDate"
          @change="updateClosedDate($event)"
        />
      </div>
      <div class="right-panel">
        <div class="ticket-number">{{ ticketDetails.ticketnumber ? '#' + ticketDetails.ticketnumber : ' ' }}</div>
        <button class="positive" @click="completeTicket">
          {{ ticketDetails.status == 0 || ticketDetails.status == null ? 'COMPELTE' : 'REOPEN' }}
        </button>
        <button
          class="danger"
          @click="setAlert('Are you sure you want to delete this ticket?', 'CANCEL', 'DELETE', 'deleteticket')"
        >
          DELETE
        </button>
        <p v-if="errStatus" @click="saveClient">error!</p>
        <p v-else-if="saveStatus">saved</p>
        <p v-else @click="saveClient">save</p>
      </div>
    </div>
    <div class="textarea">
      <label for="textarea">Ticket Details</label>
      <textarea id="textarea" autocomplete="chrome-off" v-model="ticketDetails.details" />
    </div>
    <TicketNotes :ticketid="ticketDetails.ticketid" />
    <TimeEntries :ticketid="ticketDetails.ticketid" />
  </div>
  <div v-else class="loading">
    <img src="../assets/loader-icon.svg" alt="" />
  </div>
</template>

<script>
import ClientDetails from '../components/ClientDetails.vue';
import TicketNotes from '../components/TicketNotes.vue';
import TimeEntries from '../components/TimeEntries.vue';
import ticket from '../composables/ticket';
import global from '../composables/global';

export default {
  props: ['id'],
  components: { ClientDetails, TicketNotes, TimeEntries },
  setup(props) {
    ticket.setTicketId(props.id || '');
    return {
      ticketDetails: ticket.ticketDetails,
      saveStatus: ticket.saveStatus,
      errStatus: ticket.errStatus,
      saveTicket: ticket.saveTicket,
      setAlert: global.setAlert,
      globalState: global.state,
      completeTicket: ticket.completeTicket,
      updateClosedDate: ticket.updateClosedDate 
    };
  },
  computed: {
    renderedClosedDate() {
      if (this.ticketDetails.dateclosed) {
        const date = new Date(parseInt(this.ticketDetails.dateclosed))
        return date.toISOString().substring(0,10);
      } else {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-panel {
  display: flex;
  flex-direction: column;

  .textarea {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    label {
      margin: 3px;
      font-size: 0.6em;
    }
    textarea {
      width: 100%;
      max-width: 800px;
      min-height: 100px;
      height: 100px;
      border: none;
      margin: 2px;
      font-size: 1em;
      background: #dfdfdf;
      color: #3a3a3a;
      padding: 8px;
      resize: vertical;
      &:focus {
        outline: none;
      }
    }
  }
}
.sub-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 800px;
  .inputs {
    display: flex;
    flex-direction: column;
    flex: 4;
    .space-above {
      margin-top: 20px;
    }
    .client-name {
      font-size: 1.6em;
    }
    .header-text {
      width: 100%;
    }
  }
  .right-panel {
    display: flex;
    flex-direction: column;
    width: 160px;
    align-items: flex-end;
    p {
      text-decoration: underline;
      cursor: pointer;
    }
    .ticket-number {
      margin: 35px 0px 20px 0px;
      font-size: 1.4em;
    }
    button {
      margin-top: 15px;
    }
  }
}
.loading {
  img {
    padding: 50px 70px;
    width: 200px;
    height: 200px;
  }
}
</style>

<template>
  <div class="container" :class="{ 'show-select': isOpened, 'show-client': !selectedClient.isNull && !isOpened }">
    <transition name="fade" mode="out-in">
      <div v-if="selectedClient.isNull && !isOpened" class="btn-container" @click="open" key="btn">
        Select Client
      </div>
      <div v-else-if="isOpened" class="selecting-container" key="select">
        <ul>
          <li v-for="client in filteredClients" @click="setClient(client)" :key="client.clientid">
            {{ client.name }}
          </li>
        </ul>
      </div>
      <div v-else-if="!selectedClient.isNull" class="client-container" key="clientSelected">
        <p class="client-name">
          <span @click="open">{{ selectedClient.name }}</span>
        </p>
        <select ref="contactSelect" @change="setContact" :class="{ 'not-placeholder': !selectedContact.isNull }">
          <option :value="null" disabled selected>select contact</option>
          <option
            v-for="contact in contacts"
            :selected="contact.contactid == selectedContact.contactid"
            :value="contact.contactid"
            :key="contact.contactid"
            >{{ contact.name }}</option
          >
        </select>
        <p class="contact-info">{{ selectedContact.phone }}</p>
        <p class="contact-info">{{ selectedContact.email }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
import { nextTick, ref } from 'vue';
import clients from '../composables/clients';
import contacts from '../composables/contacts';
import ticket from '../composables/ticket';

export default {
  data() {
    return {
      isOpened: false,
      searchedText: '',
      checkPrimary: false,
    };
  },
  setup() {
    const contactSelect = ref();
    clients.changeSort('name', true);
    clients.getClients();
    contacts.getContacts();
    return {
      ticketDetails: ticket.ticketDetails,
      sortedClients: clients.sortedClients,
      contacts: contacts.contacts,
      getContacts: contacts.getContacts,
      contactSelect,
    };
  },
  computed: {
    filteredClients() {
      return this.sortedClients.filter((v) => v.name.toLowerCase().includes(this.searchedText.toLowerCase()));
    },
    selectedClient() {
      const filteredClients = this.sortedClients.filter((v) => v.clientid == this.ticketDetails.clientid);
      if (filteredClients.length > 0) {
        return filteredClients[0];
      } else {
        return { isNull: true };
      }
    },
    selectedContact() {
      const filteredContacts = this.contacts.filter((v) => v.contactid == this.ticketDetails.contactid);
      if (filteredContacts.length > 0) {
        return filteredContacts[0];
      } else {
        return { isNull: true };
      }
    },
  },
  watch: {
    selectedClient() {
      this.getContacts(this.selectedClient.clientid);
    },
    selectedContact(newVal) {
      if (newVal.isNull && this.contactSelect) {
        this.contactSelect.value = '';
      }
    },
    contacts() {
      if (this.checkPrimary && this.contacts.length > 0) {
        const primaryContact = this.contacts.filter((v) => v.isprimary);
        if (primaryContact.length > 0) {
          this.ticketDetails.contactid = primaryContact[0].contactid;
        }
        this.checkPrimary = false;
      }
    },
  },
  methods: {
    open() {
      this.isOpened = true;
      this.searchedText = '';
      setTimeout(() => {
        document.getElementById('app').addEventListener('click', this.close, false);
        document.addEventListener('keyup', this.addSearchText, false);
      }, 250);
    },
    close() {
      this.isOpened = false;
      document.getElementById('app').removeEventListener('click', this.close, false);
      document.removeEventListener('keyup', this.addSearchText, false);
    },
    setClient(client) {
      this.ticketDetails.clientid = client.clientid;
      this.ticketDetails.contactid = '';
      nextTick(() => {
        this.checkPrimary = true;
        this.getContacts(client.clientid);
        this.isOpened = false;
      });
      document.getElementById('app').removeEventListener('click', this.close, false);
      document.removeEventListener('keyup', this.addSearchText, false);
    },
    setContact() {
      this.ticketDetails.contactid = this.contactSelect.value;
    },
    addSearchText(e) {
      if (e.key == 'Backspace') {
        this.searchedText = this.searchedText.slice(0, -1);
      } else if (e.key == 'Delete') {
        this.searchedText = '';
      } else if (e.key == ' ') {
        this.searchedText += ' ';
      } else if (e.key == 'Enter') {
        if (this.filteredClients.length > 0) this.setClient(this.filteredClients[0]);
      } else if (/^([a-zA-Z0-9]{1})$/.test(e.key)) {
        this.searchedText += e.key;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  border: 2px solid #dfdfdf;
  margin: 20px 0px;
  border-radius: 8px;
  display: flex;
  flex: 1 0 auto;
  height: 50px;
  transition: height 0.25s ease-out;
  box-sizing: border-box;
  width: 210px;

  .btn-container {
    font-size: 1.2em;
    text-align: center;
    color: #dfdfdf;
    padding: 10px;
    width: 100%;
    cursor: pointer;
  }
  .selecting-container {
    width: 100%;
    overflow-y: scroll;
    ul {
      padding: 0px;
      margin: 0px;
      cursor: pointer;
      li {
        list-style-type: none;
        text-align: left;
        padding: 6px;
        font-size: 1.2em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:first-of-type {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
        &:hover {
          background: #575757;
        }
      }
    }
  }
  .client-container {
    width: 100%;
    .client-name {
      margin: 0px;
      text-decoration: underline;
      font-size: 1.8em;
      padding: 0px;
      cursor: pointer;
      width: 500px;
    }
    select {
      border-bottom: none;
      margin: 0px;
      padding: 0px;
      padding-top: 3px;
      height: auto;
    }
    .contact-info {
      margin: 0px;
      font-size: 1em;
    }
  }
}

.container.show-select {
  height: 250px;
  transition: height 0.15s ease-in;
}

.container.show-client {
  height: 100px;
  border: 2px solid #3a3a3a;
  transition: height 0.15s ease-in, border 0.15s ease-in 0.15s;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

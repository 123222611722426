<template>
  <div v-if="ticketDetails.ticketid" class="notes-container" :key="timeKey">
    <p class="section-header">Ticket Notes</p>
    <div
      v-for="note in sortedNotes"
      :key="note.noteid"
      :class="{ 'note-selected': selected == note.noteid, system: note.issystem }"
      class="note"
      @mouseenter="!note.issystem ? (hovered = note.noteid) : null"
      @mouseleave="!note.issystem ? (hovered = null) : null"
      @click="!note.issystem ? selectNote(note.noteid) : null"
    >
      <div>
        <div class="header-info">
          <span>
            {{ note.systemmsg ? note.systemmsg + ',' : '' }}
            {{ note.userid ? globalState.users.filter((v) => v.userid == note.userid)[0].username + ',' : '' }}
            {{ formatedDate(note.datecreated) }}
            {{
              note.endtime != null ? ' - ' + calculateMinutes(note.starttime, note.endtime, note.paused) + ' min.' : ''
            }}
          </span>
          <span v-if="note.starttime && selected == note.noteid">
            <span class="text-span">Clocked in</span>
            <span
              type="text"
              @blur="updateTime($event, 'starttime', note.noteid)"
              contenteditable
              class="input-span"
              role="textbox"
              @keydown="captureKey($event)"
              @click="selectAll"
              >{{ formatedTime(note.starttime) }}</span
            >
            <span class="text-span">-</span>
            <span
              type="text"
              @blur="updateTime($event, 'endtime', note.noteid)"
              contenteditable
              role="textbox"
              class="input-span"
              @keydown="captureKey($event)"
              @click="selectAll"
              >{{ formatedTime(note.endtime) }}</span
            >
            <span class="text-span">with</span>
            <span
              type="text"
              @click="selectAll"
              @blur="updatePausedTime($event, note.noteid)"
              @keydown="captureKey($event)"
              contenteditable
              role="textbox"
              class="input-span"
            >
              {{ formatedMinutes(note.paused) }}</span
            >
            <span class="text-span">min. paused</span>
          </span>
        </div>
        <textarea
          v-if="!note.issystem"
          :ref="'textarea' + note.noteid"
          @input="autoExpand($event)"
          :class="{ disabled: selected != note.noteid }"
          class="note-textarea"
          v-model="note.description"
        />
      </div>
      <div class="controls">
        <img
          v-if="selected == note.noteid"
          @click="
            setAlert('Are you sure you want to delete this entry?', 'CANCEL', 'DELETE', 'deletenote', note.noteid)
          "
          class="action-btn"
          src="../assets/trash-black.svg"
          alt=""
        />
        <img
          v-else-if="hovered == note.noteid"
          @click="
            setAlert('Are you sure you want to delete this entry?', 'CANCEL', 'DELETE', 'deletenote', note.noteid)
          "
          class="action-btn"
          src="../assets/trash-white.svg"
          alt=""
        />
      </div>
    </div>
    <div class="add-note">
      <img @click="addNewNote(ticketid, $refs)" class="action-btn" src="../assets/plus-slim.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { ref, nextTick, watch } from 'vue';
import ticketNotes from '../composables/ticketNotes';
import ticket from '../composables/ticket';
import parseTime from '../composables/parseTime';
import global from '../composables/global';
import { v4 as uuidv4 } from 'uuid';

export default {
  props: ['ticketid'],
  setup(props) {
    ticketNotes.getNotes(props.ticketid);
    watch(props, () => {
      ticketNotes.getNotes(props.ticketid);
    });
    return {
      ticketNotes: ticketNotes.ticketNotes,
      setAlert: global.setAlert,
      ticketDetails: ticket.ticketDetails,
      sortedNotes: ticketNotes.sortedNotes,
      hovered: ticketNotes.hovered,
      selected: ticketNotes.selected,
      preventClose: ticketNotes.preventClose,
      globalState: global.state,
      updateTime: parseTime.updateTime,
      updatePausedTime: parseTime.updatePausedTime,
      timeKey: parseTime.timeKey,
    };
  },
  methods: {
    addNewNote(id) {
      const newId = uuidv4();
      this.ticketNotes.push({
        noteid: newId,
        ticketid: id,
        userid: this.globalState.currentUserId,
        datecreated: Date.now(),
        description: '',
        starttime: null,
        endtime: null,
        paused: null,
        issystem: false,
      });

      nextTick(() => {
        this.selectNote(newId);
        this.$refs['textarea' + newId].focus();
      });
    },
    formatedDate(time) {
      const date = new Date(parseInt(time));
      const daynumber = date.getDay();
      const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      return `${weekdays[daynumber]}, ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    },
    calculateMinutes(starttime, endtime, paused) {
      let total = endtime - starttime - paused;
      total = Math.round(total / 60000);
      if (total == 0) {
        total = 1;
      }
      return total;
    },
    formatedTime(time) {
      const date = new Date(parseInt(time));
      let hours = date.getHours();
      let minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      const strTime = `${hours}:${minutes}${ampm}`;
      return strTime;
    },
    formatedMinutes(time) {
      time = parseInt(time) / 60000;
      time = time.toFixed(1);
      return time;
    },
    selectNote(noteid) {
      if (this.selected == noteid) {
        this.preventClose = true;
      } else {
        setTimeout(() => {
          this.selected = noteid;
          document.getElementById('app').addEventListener('click', this.close, false);
        }, 50);
      }
    },
    close(event) {
      if (!this.preventClose) {
        this.selected = null;
        document.getElementById('app').removeEventListener('click', this.close, false);
      } else {
        this.preventClose = false;
      }
    },
    autoExpand($event) {
      const field = $event.target;
      field.style.height = '0px';
      const computed = window.getComputedStyle(field);
      const height = field.scrollHeight;
      field.style.height = height + 10 + 'px';
    },
    inputScroll() {
      console.log('scrolled');
    },
    captureKey(event) {
      if (event.key == 'Enter') {
        event.preventDefault();
        event.target.blur();
      }
    },
    selectAll() {
      document.execCommand('selectAll', false, null);
    },
  },
};
</script>

<style lang="scss" scoped>
.notes-container {
  margin: 3px;
  margin-top: 25px;
  max-width: 800px;
  .section-header {
    margin-bottom: 0px;
    width: 100%;
    font-size: 0.6em;
    border-bottom: 1px solid #dfdfdf;
  }
  .note {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      .header-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0px;
        padding: 10px 8px 2px 10px;

        & > span {
          font-size: 0.9em;
          color: #dfdfdf;
          margin-right: 15px;

          .input-span {
            height: 18px;
            text-align: center;
            padding: 0px;
            margin: 0px;
            border: none;
            text-decoration: underline;
            background: #dfdfdf;
            color: #3a3a3a;
            outline: none;
          }
          .text-span {
            color: #3a3a3a;
            margin: 0px 5px;
          }
        }
      }
      textarea {
        width: 100%;
        outline: none;
        margin: 0px 10px;
        color: #dfdfdf;
        background: #3a3a3a;
        border: none;
        font-size: 1.4em;
        padding: 0px;
        resize: none;
        height: 35px;
      }
    }
    .controls {
      width: 60px;
      margin: auto 10px;
      text-align: right;
      display: block;
      img {
        background: transparent;
        width: 25px;
      }
    }
    &:hover {
      background: #575757;
      * {
        background: #575757;
      }
    }
  }
  .add-note {
    margin-left: 10px;
    margin-top: 15px;
  }
}

textarea.disabled {
  pointer-events: none;
}

.notes-container > .note-selected {
  background: #dfdfdf;
  cursor: default;
  div {
    .header-info {
      & > span {
        color: #3a3a3a;
      }
    }
    textarea {
      color: #3a3a3a;
      background: #dfdfdf;
    }
  }
  &:hover {
    background: #dfdfdf;
    * {
      background: #dfdfdf;
    }
  }
}

.system {
  &:hover {
    background: #3a3a3a !important;
    * {
      background: #3a3a3a !important;
    }
  }
}
</style>

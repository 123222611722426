import { ref } from 'vue';
import ticketNotes from './ticketNotes';

// used to force update
const timeKey = ref(1);

const updateTime = (event: KeyboardEvent, field: 'starttime' | 'endtime', noteid: string) => {
  const target = event.target as HTMLInputElement;
  const time = target.innerHTML;

  let hour,
    minute,
    pm = time.match(/p/i) !== null;
  const format = 'G:i a',
    num = time.replace(/[^0-9]/g, ''),
    am = time.includes('a') || false;

  // Get current Date
  let currentTime = ticketNotes.ticketNotes.value.filter((v) => v.noteid == noteid)[0][field] || 0;
  currentTime = parseInt(currentTime.toString());
  const newDate = new Date(currentTime);

  // Parse for hour and minute
  switch (num.length) {
    case 4:
      hour = parseInt(num[0] + num[1], 10);
      minute = parseInt(num[2] + num[3], 10);
      break;
    case 3:
      hour = parseInt(num[0], 10);
      minute = parseInt(num[1] + num[2], 10);
      break;
    case 2:
    case 1:
      hour = parseInt(num[0] + (num[1] || ''), 10);
      minute = 0;
      break;
    default:
      timeKey.value++;
      return '';
  }

  // Make sure hour is in 24 hour format
  if (pm === true && hour > 0 && hour < 12) hour += 12;

  // Force pm for hours between 13:00 and 23:00
  if (hour >= 13 && hour <= 23) pm = true;

  // Fix hour if 12am
  if (hour == 12 && pm === false) hour = 0;

  // Keep within range
  if (hour <= 0 || hour >= 24) hour = 0;
  if (minute < 0 || minute > 59) minute = 0;

  // Correct if no am/pm is set
  if (!am && hour < 12 && newDate.getHours() >= 12) {
    hour += 12;
    pm = true;
  }

  /* // Format output
    const result = format
      // 12 hour without leading 0
      .replace(/g/g, hour === 0 ? '12' : 'g')
      .replace(/g/g, hour > 12 ? (hour - 12).toString() : hour.toString())
      // 24 hour without leading 0
      .replace(/G/g, hour.toString())
      // 12 hour with leading 0
      .replace(
        /h/g,
        hour.toString().length > 1 ? (hour > 12 ? hour - 12 : hour).toString() : '0' + (hour > 12 ? hour - 12 : hour)
      )
      // 24 hour with leading 0
      .replace(/H/g, hour.toString().length > 1 ? hour.toString() : '0' + hour)
      // minutes with leading zero
      .replace(/i/g, minute.toString().length > 1 ? minute.toString() : '0' + minute)
      // simulate seconds
      .replace(/s/g, '00')
      // lowercase am/pm
      .replace(/a/g, pm ? 'pm' : 'am')
      // uppercase am/pm
      .replace(/A/g, pm ? 'PM' : 'AM');
  */

  newDate.setHours(hour);
  newDate.setMinutes(minute);
  if (hour == 24) newDate.setDate(newDate.getDate() - 1);
  ticketNotes.ticketNotes.value.filter((v) => v.noteid == noteid)[0][field] = newDate.getTime();
  timeKey.value++;
};

const updatePausedTime = (event: KeyboardEvent, noteid: string) => {
  const target = event.target as HTMLInputElement;
  const value = target.innerHTML;
  const time = parseFloat(value.replace(/[^0-9.]/g, '')) || 0.0;
  const millis = Math.round(time * 60000);
  ticketNotes.ticketNotes.value.filter((v) => v.noteid == noteid)[0].paused = millis;
  timeKey.value++;
};

export default {
  updateTime,
  updatePausedTime,
  timeKey,
};
